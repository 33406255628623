import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

export default function Index({ data }) {
  return(
    <Layout width={"720px"}>		
        <div className="container">
    			<div className="row justify-content-center">
    				<div className="col index--inner">
    				    {data.allMarkdownRemark.edges.map(({ node }) => (
    				      <article key={node.id}>
    				        <Link to={node.fields.slug}>
    	              <h3 dangerouslySetInnerHTML={{__html: node.frontmatter.title}} />
    				        </Link>
    				        <p dangerouslySetInnerHTML={{__html: node.frontmatter.customExcerpt || node.excerpt}} />
    				      </article>
    				    ))}		
    				</div>
    			</div>
    		</div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
       sort: { fields: [frontmatter___publishDate], order: DESC },
       filter: {frontmatter: {isDraft: {ne: true}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            customExcerpt
            title
          }
          fields {
            slug
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`
